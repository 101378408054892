import React from 'react';
import { isBrowser } from '../../utils/isBrowser';

import EpisodeMedia from './EpisodeMedia';

const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const WatchGrid = ({ carouselItems }) => {
  return (
    <div className="watch-carousel">
      <div className="layout-media layout-media-watch visible-sm">
        <div className="head">
          <div className="cols item-1 featured">
            <EpisodeMedia episode={carouselItems[0][0]} className="layout-media featured-aspect-ratio" latest />
          </div>
          <div className="cols item-2 d-flex">
            <div className="cols-inner item-inner-1">
              <EpisodeMedia episode={carouselItems[0][1]} />
            </div>
            <div className="cols-inner item-inner-2">
              <EpisodeMedia episode={carouselItems[0][2]} />
            </div>
          </div>
        </div>

        <div className="tail">
          <div className="cols item-1">
            <EpisodeMedia episode={carouselItems[0][3]} className="layout-media default-aspect-ratio" />
          </div>
          <div className="cols item-1">
            <EpisodeMedia episode={carouselItems[0][4]} />
          </div>
        </div>
      </div>

      <OwlCarousel
        className="carousel-stage owl-carousel la-slider visible-md"
        loop
        responsiveClass
        navigation={true}
        responsive={{
          0: {
            items: 1,
            nav: true,
          },
          768: {
            items: 1,
            nav: true,
          },

          1200: {
            items: 1,
            nav: true,
            loop: true,
            dots: false,
          },
        }}
      >
        {carouselItems?.length > 0 &&
          carouselItems.map((item, index) => (
            <div className="layout-media layout-media-watch " key={index}>
              <div className="head d-flex">
                <div className="cols item-1 featured">
                  <EpisodeMedia episode={item[0]} className="layout-media featured-aspect-ratio" latest={index === 0} />
                </div>
                <div className="cols item-2 d-flex">
                  <div className="cols-inner item-inner-1">
                    <EpisodeMedia episode={item[1]} />
                  </div>
                  <div className="cols-inner item-inner-2">
                    <EpisodeMedia episode={item[2]} />
                  </div>
                </div>
              </div>

              <div className="tail d-flex">
                <div className="cols item-1">
                  <EpisodeMedia episode={item[3]} className="layout-media default-aspect-ratio" />
                </div>
                <div className="cols item-1">
                  <EpisodeMedia episode={item[4]} />
                </div>
              </div>
            </div>
          ))}
      </OwlCarousel>
    </div>
  );
};

export default WatchGrid;
