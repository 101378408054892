import { Link } from 'gatsby';

import Loadable from 'react-loadable';
import React, { useState } from 'react';

import TestimonialDetailPopup from './TestimonialDetailPopup';

const loader = () => <div>Loading...</div>;

const LoadableCarousel = Loadable({
  loader: () => import('./TestimonialCarousel'),
  loading: loader,
});

const Testimonials = ({ testimonials }) => {
  const [selectedTestimonial, setSelectedTestimonial] = useState();

  return (
    <section className="index-testimonials-home">
      {!!selectedTestimonial && (
        <TestimonialDetailPopup testimonial={selectedTestimonial} toggleModal={setSelectedTestimonial} />
      )}
      <div className="bg-white section wrapper wrapper-xl   pd-x-sm index-testimonials-container">
        <h3 className="sectionHeading pd-x-sm testimonials-heading">TESTIMONIALS</h3>
        <div className="testimonial-container">
          <div className="wrapper wrapper-xl pd-x-md">
            <div className="testimonials">
              <div className="wrapper wrapper-lg pd-x-sm">
                <div className="carousel pd-x-sm">
                  <LoadableCarousel testimonials={testimonials} openTestimonialPopup={setSelectedTestimonial} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-btn">
          <Link to="/testimonial" className="btn btn-sm btn-primary-ii btn-watch-now">
            VIEW ALL
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
