import React from 'react';
import { Link } from 'gatsby';
import iconPlayAlt from '../../images/icon-play-alt.svg';
import PlayIcon from '../../images/icons-common/playIcon';
import { formateURLLink } from '../../utils/watch';

export default function WatchMedia({ episode, isFirst }) {
  if (!episode) return null;

  let imageUrl = episode.data?.thumbnailImage?.url;
  let watchCategory = isFirst ? 'BTS' : episode?.data?.categories[0]?.episodeCategory?.categoryType;

  const title = episode?.data?.title.length ? episode?.data?.title[0]?.text?.replace(/-/g, '') : '';
  const categoryWithNoHyphen = watchCategory.replace(/-/g, ' ');
  return (
    <Link
      to={`/video_episodes/${formateURLLink(episode?.uid)}`}
      className={isFirst ? 'media ratio_4-3' : 'cols-inner d-flex'}
    >
      {isFirst && <div className="m_overlay"></div>}
      {isFirst ? (
        <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }} />
      ) : (
        <div className="media">
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className="m_border"></div>
        </div>
      )}
      <div className="media-content">
        <span className={isFirst ? 'mediawatch-category text-light' : 'mediawatch-category opacity-low'}>
          {categoryWithNoHyphen}
        </span>
        <h2 className="mediawatch-title ">{title}</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div className="btn btn-watch-play btn-transparent mediawatch-explore-button">
            {isFirst ? <img src={iconPlayAlt} alt="Icon Play" className="icon-play-alt" /> : <PlayIcon />}
            <span className="text-light">Watch</span>
          </div>

          {isFirst && (
            <div className="m_run-time ">
              <span>{episode?.data?.videoDuration[0]?.text}</span>
            </div>
          )}
        </div>
      </div>
      {isFirst && (
        <>
          <div className="m_run-time m_run-time-outer-top-left">
            <span>Latest</span>
          </div>
          <div className="m_border" />
        </>
      )}
    </Link>
  );
}
