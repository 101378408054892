import React from 'react';

import Grid from '../Grid';
import { decode } from 'html-entities';
import renderHtml from 'htmr';
import { Link } from 'gatsby';

const MissionStatement = ({ mission }) => {
  return (
    <div className="mission-statements pd-y-0">
      <h3 className="mission-heading">{mission.data?.title?.text}</h3>
      <div className="mission-descriptions">{renderHtml(decode(mission.data?.statement?.html))}</div>

      <div
        style={{
          maxWidth: '890.22px',
          margin: '0 auto 64px auto',
        }}
      >
        <Grid>
          <Grid.Col xs="1 / span 2" sm="1 / span 1" md="1 / span 6">
            <img
              src={mission.data.images[0].image.fluid.src}
              //   alt={tour.data.homepagePreviewImage1.alt || 'Tour image'}
              style={{
                width: '100%',
                aspectRatio: '423/238',
                objectFit: 'cover',
              }}
            />
          </Grid.Col>

          <Grid.Col xs="1 / span 2" sm="2 / span 1" md="7 / span 6">
            <img
              src={mission.data.images[1].image.fluid.src}
              //   alt={tour.data.homepagePreviewImage2.alt || 'Tour image'}
              style={{
                width: '100%',
                aspectRatio: '423/238',
                objectFit: 'cover',
              }}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to={`/about`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
          Learn More About Us
        </Link>
      </div>
    </div>
  );
};

export default MissionStatement;
