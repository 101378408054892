import React from 'react';
import { Link } from 'gatsby';
import { getReadThumbnailFromRead } from '../../utils/functions';
import iconRead from '../../images/icon-bars-alt.svg';

export default function ReadMedia({ read, isFirst }) {
  if (!read) return null;

  let readCategory = 'Featured';
  if (read && read.data.readCategories && read.data.readCategories.length) {
    const readCat = read?.data?.readCategories?.find(
      (read) =>
        read?.readCategory?.featuredCategory?.startsWith('season') ||
        read.readCategory?.featuredCategory?.startsWith('blog')
    );
    if (readCat) readCategory = readCat?.readCategory?.featuredCategory;
  }

  const backgroundImage = read?.data?.featuredImage?.url;
  const readCategoryWithNoHyphen = readCategory.replace('-', ' ');

  return (
    <Link to={read.url} className={isFirst ? 'media ratio_4-3' : 'cols-inner d-flex'}>
      {isFirst && <div className="m_overlay"></div>}
      {isFirst ? (
        <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${backgroundImage})` }} />
      ) : (
        <div className="media">
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${backgroundImage})` }} />
          <div className="m_border"></div>
        </div>
      )}
      <div className="media-content">
        <span className="media-category">{readCategoryWithNoHyphen}</span>
        <h2 className="media-title ">{read?.data?.title?.text}</h2>
        <div className="btn btn-read btn-transparent">
          <img src={iconRead} alt="Icon Read" className="icon-read" />
          <span>Read</span>
        </div>
      </div>
      {isFirst && (
        <>
          <div className="m_run-time m_run-time-outer-top-left">
            <span>Latest</span>
          </div>
          <div className="m_border" />
        </>
      )}
    </Link>
  );
}
