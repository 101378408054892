import React from 'react';
import { Link } from 'gatsby';
import iconPlayAlt from '../../images/icon-play-alt.svg';

export default function Media({
  imageURL,
  category,
  title,
  link,
  className,
  typeColor,
  mediaType = 'Watch',
  scale = false,
  latest = false,
  duration,
}) {
  return (
    <Link to={link} className={`media ${className}`}>
      <div className="m_overlay"></div>
      <div
        className={`m_overlay-image bgImage ${scale ? 'scale-155' : ''}`}
        style={{ backgroundImage: `url(${imageURL})` }}
      />
      <div className="media-content" style={{ width: '100%' }}>
        {category && <span className="media-category">{category}</span>}
        <h5 className="media-title h-clamp-line-2">{title}</h5>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div className="btn btn-watch-play btn-transparent">
            <img src={iconPlayAlt} alt="Icon Play" className="icon-play-alt" />
            <span style={{ color: typeColor }}>{mediaType}</span>
          </div>
          {duration && (
            <div className="m_run-time ">
              <span>{duration}</span>
            </div>
          )}
        </div>
      </div>

      {latest && (
        <div className="m_run-time m_run-time-outer-top-left">
          <span>Latest</span>
        </div>
      )}
      <div className="m_border" />
    </Link>
  );
}
