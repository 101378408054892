import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

export default function ProductCategory({ title, image, link }) {
  return (
    <div className="cols list-item">
      <Link to={link}>
        <div className="product-head">
          <span className="cat-title">{title}</span>
        </div>
        <Image
          className="list-figure product-item bg-image"
          fluid={image}
          imgStyle={{ objectFit: 'cover', objectPosition: 'center middle' }}
        />
      </Link>
    </div>
  );
}
