import React from 'react';

import Media from './Media';
import { getEpisodeThumbnail, formateURLLink } from '../../utils/watch';

export default function EpisodeMedia({ episode, className, latest = false }) {
  if (!episode) return null;

  let title,
    imageUrl,
    mediaType,
    link,
    watchCategory = '';
  if (episode?.data?.podCastTitle) {
    mediaType = 'Listen';
    imageUrl = episode?.data?.thumnailImage?.url;
    title = episode?.data?.podCastTitle.length ? episode?.data?.podCastTitle[0]?.text : '';
    link = `/podcast/${formateURLLink(episode?.uid)}`;
    latest = true;
  } else {
    mediaType = 'Watch';
    watchCategory = 'Featured';
    imageUrl = getEpisodeThumbnail(episode.data);

    if (episode && episode.data.categories && episode.data.categories.length > 1) {
      const watchCat = episode.data.categories.find((cat) => cat.episodeCategory.categoryType !== 'featured');
      if (watchCat) watchCategory = watchCat.episodeCategory.categoryType;
    }

    title = episode?.data?.title.length ? episode?.data?.title[0]?.text?.replace(/-/g, '') : '';
    watchCategory = watchCategory.replace(/-/g, ' ');
    link = `/video_episodes/${formateURLLink(episode?.uid)}`;
  }

  return (
    <Media
      imageURL={imageUrl}
      category={watchCategory}
      title={title}
      link={link}
      className={className}
      latest={latest}
      mediaType={mediaType}
    />
  );
}
