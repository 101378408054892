import renderHTML from 'htmr';
import Image from 'gatsby-image';
import { Link, graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import DonateImage from '../images/bg-donate.jpg';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BackgroundVideo from '../components/Homepage/BackgroundVideo';
import Media from '../components/Homepage/Media';
import ReadMedia from '../components/Homepage/ReadMedia';
import SubscribeForm from '../components/Form/SubscribeForm';
import ProductCategory from '../components/Homepage/ProductCategory';
import Testimonials from '../components/Testimonial/Testimonial';
import iconInstagramAlt from '../images/icon-instagram-alt.svg';
import ContactForm from '../components/ContactForm/contact-form';
import FlagsLists from '../components/Homepage/FlagsLists';
import Tour from '../components/Homepage/Tour';
import WatchMedia from '../components/Homepage/WatchMedia';
import WatchGrid from '../components/Homepage/WatchGrid';
import MissionStatement from '../components/Homepage/MissionStatement';
import ProjectCarousel from '../components/Donation/ProjectsCarousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import iconUserAlt from '../images/icon-user-alt.svg';
import TeamSection from '../components/Homepage/Teams';

const HomePage = (props) => {
  const {
    data: {
      bgIndex,
      homeTimeframe,
      shopImageWear,
      shopImageRead,
      shopImageWatch,
      bgNewsletter,
      latestRead,
      season1Read,
      season2Read,
      season3Read,
      backgroundVideos,
      testimonials,
      watchSeason1,
      watchSeason2,
      watch7Churches,
      watchTheGreatControversy,
      watchCreationToKings,
      watchKingsToCaptivity,
      watchManagerToMessiah,
      watchPentecostToPatmos,
      watchDocumentaries,
      watchlocationsFromTheSky,
      watchAdverts,
      watch360,
      btsSeason1,
      btsSeason2,
      btsSeason3,
      btsLatestEpisodes,
      latestEpisodes,
      language,
      podCastData,
      storyBg,
      tourData,
      tourLanding,
      missionStatement,
      donationsDetails,
      allPrismicTeamMembers,
      teamSingleMap,
      donateBgRead,
    },
  } = props;
  const [subscribeFormSubmitted, setSubscribeFormSubmitted] = useState(false);
  const [activeTabId, setActiveTabId] = useState(0);
  const onSubscribe = (data) => {
    setSubscribeFormSubmitted(data);
  };

  const tourExists = tourData.nodes.length > 0 && tourLanding.nodes.length > 0;

  const jumbotronCarouselEpisodes = [
    [
      latestEpisodes.nodes[0],
      watchSeason1.nodes[0],
      watchSeason2.nodes[0],
      watch7Churches.nodes[0],
      watchTheGreatControversy.nodes[0],
    ],
    [
      watchCreationToKings.nodes[0],
      watchKingsToCaptivity.nodes[0],
      watchManagerToMessiah.nodes[0],
      watchPentecostToPatmos.nodes[0],
      watchDocumentaries.nodes[0],
    ],
    [
      watchlocationsFromTheSky.nodes[0],
      btsLatestEpisodes.nodes[0],
      watchAdverts.nodes[0],
      podCastData.nodes[0],
      watch360.nodes[0],
    ],
  ];

  return (
    <BodyClassName className="body-light page-home">
      <DefaultLayoutComponent
        title="Lineage Journey - Identity, Purpose, Mission"
        description="Lineage is a multi-faceted educational tool designed to help you understand your spiritual heritage and explore the links between the past, present and future."
        // seo={wpPage.seo}
      >
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage d-flex align-items-center"
            fluid={bgIndex.childImageSharp.fluid}
            preserveStackingContext>
            <BackgroundVideo videos={backgroundVideos.files.slice(1)} />
            <div className="overlay">
              <div className="wrapper wrapper-lg pd-x-sm pb-title-wrapper">
                <div className="pb-container pd-x-sm">
                  <h1 className="pb-title">IDENTITY. PURPOSE. MISSION.</h1>
                  <p className="pb-sub-title">Discovering Purpose in the Present by Understanding Our Past</p>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <section className="watch-section bg-dark txt-light">
            <div
              className="wrapper"
              style={{
                maxWidth: '873px',
              }}>
              <>
                <WatchGrid carouselItems={jumbotronCarouselEpisodes} />
                <div className="explore-link">
                  <Link to="/watch" className="btn btn-explore">
                    Explore All
                  </Link>
                </div>
              </>
            </div>
          </section>
          <section className=" bg-light txt-dark">
            <div className="wrapper wrapper-lg pd-x-sm">
              <MissionStatement mission={missionStatement.edges[0].node} />
              {/* <h3 className="sectionHeading">Timeline of church history</h3>
              <div className="d-flex justify-content-center">
                <Link className="btn btn-sm btn-primary-ii btn-timeline">Coming Soon</Link>
              </div>
              <Image
                className="img-timeline"
                fluid={homeTimeframe.childImageSharp.fluid}
                imgStyle={{ objectPosition: 'center top' }}
              /> */}
            </div>
          </section>
          <section className="layout-media layout-media-read bg-dark txt-light">
            <div className="wrapper wrapper-lg pd-x-sm">
              <h3 className="sectionHeading">Read</h3>
              <div className="d-flex">
                <div className="cols item-1 featured">
                  <ReadMedia read={latestRead.nodes[0]} isFirst />
                </div>
                <div className="cols item-2 d-flex">
                  <ReadMedia read={season1Read.nodes[0]} />
                  <ReadMedia read={season2Read.nodes[0]} />
                  <ReadMedia read={season3Read.nodes[0]} />
                </div>
              </div>
              <div className="explore-link">
                <Link to="/read" className="btn btn-explore">
                  Explore All
                </Link>
              </div>
            </div>
          </section>

          <section className="bg-light txt-dark">
            <div className="wrapper wrapper-lg">
              <h1 className="sectionHeading">Podcast</h1>

              <>
                <div className="layout-media layout-media-watch layout-media-podcast d-flex">
                  <div className="cols item-1 featured">
                    <Media
                      imageURL={podCastData.nodes[0].data.thumnailImage.url}
                      typeColor="white"
                      title={podCastData.nodes[0].data.podCastTitle[0].text}
                      link={`/podcast/${podCastData.nodes[0]?.uid}`}
                      className="ratio_4-3"
                      latest={true}
                      mediaType={'Listen'}
                      duration={podCastData.nodes[0].data.podcastDuration}
                    />
                  </div>
                  <div className="cols item-2 d-flex">
                    <div className="cols-inner item-inner-1">
                      {podCastData.nodes.length > 1 && (
                        <Media
                          imageURL={podCastData.nodes[1].data.thumnailImage.url}
                          typeColor="white"
                          title={podCastData.nodes[1].data.podCastTitle[0].text}
                          link={`/podcast/${podCastData.nodes[1]?.uid}`}
                          mediaType={'Listen'}
                          latest={false}
                          duration={podCastData.nodes[1].data.podcastDuration}
                        />
                      )}
                    </div>
                    <div className="cols-inner item-inner-2 d-flex">
                      <div className="cols-inner-item item-in-in-1">
                        {podCastData.nodes.length > 2 && (
                          <Media
                            imageURL={podCastData.nodes[2].data.thumnailImage.url}
                            typeColor="white"
                            title={podCastData.nodes[2].data.podCastTitle[0].text}
                            link={`/podcast/${podCastData.nodes[2]?.uid}`}
                            mediaType={'Listen'}
                            latest={false}
                            duration={podCastData.nodes[2].data.podcastDuration}
                          />
                        )}
                      </div>
                      <div className="cols-inner-item item-in-in-2">
                        {podCastData.nodes.length > 3 && (
                          <Media
                            imageURL={podCastData.nodes[3].data.thumnailImage.url}
                            title={podCastData.nodes[3].data.podCastTitle[0].text}
                            link={`/podcast/${podCastData.nodes[3]?.uid}`}
                            mediaType={'Listen'}
                            typeColor="white"
                            latest={false}
                            duration={podCastData.nodes[3].data.podcastDuration}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="explore-link ">
                  <Link
                    to="/podcast"
                    className="btn btn-explore"
                    style={{ color: 'black', borderBottom: '2px solid #0A0A0A' }}>
                    Explore All
                  </Link>
                </div>
              </>
            </div>
          </section>

          {tourExists && (
            <section className="layout-media layout-media-read bg-dark txt-light">
              <div className="wrapper wrapper-lg pd-x-sm">
                <h3 className="sectionHeading">Tour</h3>
                <Tour tours={tourData.nodes} />
              </div>
            </section>
          )}

          <div className="donation-project-home-page" style={{ position: 'relative' }}>
            <div className="wrapper wrapper-xl ">
              <div className=" project-carousel-wrapper">
                <h3 className="title" style={{ textAlign: 'center' }}>
                  GIVE
                </h3>
                <p className="subtitle">
                  Your contribution in any project ensures the continuity of traditions and wisdom. Join the global
                  effort, celebrate diversity, and safeguard our shared legacy. Give now!
                </p>
                <div className="project-carousel-container bg-white">
                  <div>
                    <div>
                      <ProjectCarousel projects={donationsDetails.edges[0].node.data.projects} />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: '97px', textAlign: 'center' }}>
                  <button onClick={() => navigate('/give')} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                    EXPLORE MORE PROJECTS
                  </button>
                </div>
              </div>
            </div>
          </div>

          <BackgroundImage
            Tag="section"
            className="newsletter s_overlay-reverse bgImage txt-light"
            fluid={storyBg.childImageSharp.fluid}
            preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm">
              <h3 className="sectionHeading pd-x-sm">Our Story</h3>
              <p className="p-lg pd-x-md" style={{ textAlign: 'center' }}>
                Lineage is a ministry geared towards producing multi-faceted educational resources that aim to make
                history both relevant and accessible to people of all ages. We live in a world where people are almost
                constantly engaging with media like never before and providing resources that are short and shareable is
                one of our key goals.
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Link to="/about" className="btn btn-sm btn-primary-ii btn-watch-now">
                  Find Out More
                </Link>
              </div>
            </div>
          </BackgroundImage>
          <section className="index-shop">
            <div className="wrapper wrapper-lg pd-x-sm">
              <h3 className="sectionHeading pd-x-sm">Lineage Shop</h3>
              <p className="p-lg pd-x-md">
                Checkout our exclusive Lineage merchandise and resources in our online shop!
              </p>
              <div className="product-container">
                <div className="product-list columns-3 d-flex">
                  <ProductCategory
                    title="WEAR"
                    link="/product-category/wear"
                    image={shopImageWear.childImageSharp.fluid}
                  />
                  <ProductCategory
                    title="READ"
                    link="/product-category/read"
                    image={shopImageRead.childImageSharp.fluid}
                  />
                  <ProductCategory
                    title="WATCH"
                    link="/product-category/watch"
                    image={shopImageWatch.childImageSharp.fluid}
                  />
                </div>
              </div>
            </div>
          </section>
          <BackgroundImage
            Tag="section"
            className="newsletter s_overlay-reverse bgImage txt-light"
            fluid={storyBg.childImageSharp.fluid}
            // fluid={controvercyImage}
            preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm">
              <h3 className="sectionHeading pd-x-sm">The Great Controversy</h3>
              <p className="p-lg pd-x-md" style={{ textAlign: 'center' }}>
                The Great Controversy is a book by Ellen G. White, one of the founders of the Seventh-day Adventist
                Church, and held in esteem as a prophetess or messenger of God among Seventh-day Adventist members.
              </p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Link to="/books/the-great-controversy" className="btn btn-sm btn-primary-ii btn-watch-now">
                  EXPLORE NOW
                </Link>
              </div>
            </div>
          </BackgroundImage>
          <section className="layout-media layout-media-read bg-light txt-dark">
            <div className="wrapper wrapper-lg pd-x-sm">
              <h3 className="sectionHeading">BEHIND THE SCENES</h3>
              <div className="mediawatch-subtitle p-lg pd-x-md ">
                What is it really like to film on location? watch and see the challenges, joys, struggles and realities
                of filming these episodes.
              </div>
              <div className="d-flex">
                <div className="cols item-1 featured">
                  <WatchMedia episode={btsLatestEpisodes.nodes[0]} isFirst />
                </div>
                <div className="cols item-2 d-flex">
                  <WatchMedia episode={btsSeason1.nodes[0]} />
                  <WatchMedia episode={btsSeason2.nodes[0]} />
                  <WatchMedia episode={btsSeason3.nodes[0]} />
                </div>
              </div>
              <div className="explore-link">
                <Link
                  to="/watch"
                  className="btn btn-explore "
                  style={{ color: 'black', borderBottom: '2px solid #0A0A0A' }}>
                  Explore All
                </Link>
              </div>
            </div>
          </section>

          <BackgroundImage
            Tag="section"
            className="newsletter s_overlay-reverse bgImage txt-light"
            fluid={bgNewsletter.childImageSharp.fluid}
            preserveStackingContext>
            <div className="overlay" />
            <div className="wrapper wrapper-md pd-x-md">
              {subscribeFormSubmitted && subscribeFormSubmitted.result === 'success' ? (
                <h3 className="sectionHeading">
                  Thank you for subscribing!
                  <br />
                  We are excited to bring History to life, right to your inbox.
                </h3>
              ) : (
                <>
                  <h3 className="sectionHeading">Subscribe To Our Newsletter</h3>
                  <SubscribeForm page="home" callback={onSubscribe} />
                  <div className="note-form">
                    {subscribeFormSubmitted && subscribeFormSubmitted.result === 'error'
                      ? renderHTML(subscribeFormSubmitted.msg)
                      : null}
                    <br />
                    By submitting your information, you agree to receive emails from Lineage Journey. You can opt out at
                    any time.
                  </div>
                </>
              )}
            </div>
          </BackgroundImage>
          <section className="language-section">
            <div className="wrapper pd-x-sm bg-white">
              <h3 className="sectionHeading pd-x-sm">Lineage in your language</h3>
              <p className="p-xl">
                {' '}
                Do you speak another language? Lineage episodes may be available in your native tongue, either dubbed or
                via subtitles!
              </p>
              <div className="wrapper-lg wrapper-ul pd-x-md ">
                <div className="flag-list-wrapper">
                  <FlagsLists flagLists={language.nodes} />
                </div>
                <ul className="tab-head give-head method-donation d-flex">
                  <li className={`tab-item  btn btn-sm `} key={'0'}>
                    <Link to="/language">{`More information`}</Link>
                  </li>
                  <li className={`tab-item btn btn-sm`} key={'0'} style={{ marginLeft: 5 }}>
                    <Link to="/contact">{`Contact to volunteer`}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="layout-media layout-media-read bg-dark txt-light">
            <div className="wrapper wrapper-xl pd-x-sm pd-x-0">
              <div className="index-team home-page-team">
                <h3>MEET THE TEAM</h3>
                <h4>
                  The backbone of our Lineage journey, our team is a powerhouse of talent and dedication, driving our
                  innovation and success with passion and expertise.
                </h4>
                <TeamSection allPrismicTeamMembers={allPrismicTeamMembers} teamSingleMap={teamSingleMap} />
                <div style={{ marginTop: '64px', textAlign: 'center' }}>
                  <button onClick={() => navigate('/team')} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                    VIEW ALL
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* <BackgroundImage
            className="s_overlay-end   bgImage txt-light"
            fluid={donateBgRead.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" /> */}
          <Testimonials testimonials={testimonials} />
          {/* </BackgroundImage> */}

          {/* <section
            className=""
            style={{
              backgroundImage: `url(${DonateImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            <div className=" bg-white"> */}
          {/* <h3 className="sectionHeading pd-x-sm">GIVE</h3>
              <p className="p-xl">
                {' '}
                Lineage began as a seed. A small idea packed with big potential. True to its nature, that seed has grown
                beyond our wildest expectations and has reached people in ways we could never have imagined.
              </p> */}

          {/* <div className="wrapper-lg wrapper-ul pd-x-md ">
                <ul className="tab-head give-head method-donation d-flex">
                  <li
                    className={`tab-item btn btn-sm ${activeTabId === 0 ? 'active' : ''}`}
                    style={{ cursor: 'pointer' }}
                    key={'0'}
                    onClick={() => {
                      setActiveTabId(0);
                    }}>
                    <Link to="/give">{`Go to Give Page`}</Link>
                  </li>
                </ul>
              </div> */}
          {/* </div>
          </section> */}

          <section className="   contact-section  bg-dark txt-light">
            <div className="wrapper pd-x-sm ">
              <h3 className="sectionHeading pd-x-sm">drop us a line</h3>
              <div className="wrapper wrapper-lg pd-x-sm">
                <ContactForm />
              </div>
            </div>
          </section>
          <section className="index-social">
            <h3 className="sectionHeading pd-x-md">
              <a href="https://www.instagram.com/lineagejourney/" target="_blank">
                <span>Follow Us On</span>
                <span>
                  <img src={iconInstagramAlt} alt="Instagram" className="icon-insta" />
                  Instagram
                </span>
              </a>
            </h3>
            <div className="insta-gallery">
              <div className="elfsight-app-58f89894-78f0-48a8-bb54-4529326bc8b2"></div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};
export const query = graphql`
  fragment WatchEpisodeDataFragment on PrismicEpisodes {
    data {
      categories {
        episodeCategory: episodes_category {
          categoryType: slug
        }
      }
      videoDuration: video_duration {
        text
      }
      youtubeId: youtube_id {
        text
      }
      title {
        text
      }
      thumbnailImage: thumbnail_image {
        url
      }
    }
    slugs
    uid
    url
  }

  fragment ReadDataFragment on PrismicRead {
    data {
      readCategories: read_categories {
        readCategory: read_category {
          featuredCategory: slug
        }
      }
      featuredImage: featured_image {
        alt
        url
      }
      title {
        text
      }
    }
    url
  }

  {
    backgroundVideos: allS3Object {
      files: nodes {
        url
        Key
        Size
      }
    }
    bgIndex: file(relativePath: { eq: "season-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    homeTimeframe: file(relativePath: { eq: "home-timeframe.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    storyBg: file(relativePath: { eq: "lineage-italy.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgNewsletter: file(relativePath: { eq: "bg_newsletter.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    shopImageWear: file(relativePath: { eq: "wear-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    shopImageRead: file(relativePath: { eq: "read-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    shopImageWatch: file(relativePath: { eq: "watch-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    donateBgRead: file(relativePath: { eq: "testimonials-bg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonials: allPrismicTestimonials {
      nodes {
        data {
          title {
            text
          }
          position {
            text
          }
          featuredImage: featured_image {
            url
          }
          description {
            text
          }
          address {
            text
          }
        }
      }
    }

    latestEpisodes: allPrismicEpisodes(
      filter: { data: { language: { eq: "English" } } }
      sort: { fields: first_publication_date, order: DESC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    tourLanding: allPrismicTourLanding {
      nodes {
        id
      }
    }

    tourData: allPrismicTour {
      nodes {
        uid
        data {
          homepagePreviewImage1: homepage_preview_image_1 {
            alt
            url
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid
            }
          }
          homepagePreviewImage2: homepage_preview_image_2 {
            alt
            url
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid
            }
          }
          pricePerPersonSingleOccupancy: price_per_person_single_occupancy
          pricePerPersonDoubleOccupancy: price_per_person_double_occupancy
          startDate: tour_start_date
          title: tour_title {
            text
          }
          subtitle: tour_subtitle {
            text
          }
          description {
            text
          }
        }
      }
    }

    watchSeason1: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-1"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchSeason2: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-2"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchSeason3: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-3"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watch7Churches: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["the-7-churches-of-revelation"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchTheGreatControversy: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["the-great-controversy"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchCreationToKings: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["creation-to-kings"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchKingsToCaptivity: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["kings-to-captivity"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchManagerToMessiah: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["manger-to-messiah"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchPentecostToPatmos: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["pentecost-to-patmos"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchDocumentaries: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["documentaries"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchlocationsFromTheSky: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["locations-from-the-sky"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watchAdverts: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["adverts"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    watch360: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["360-tours"] } } } }
          language: { eq: "English" }
          bts: { eq: "NO" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }

    btsLatestEpisodes: allPrismicEpisodes(
      filter: { data: { language: { eq: "English" }, bts: { eq: "YES" } } }
      sort: { fields: first_publication_date, order: DESC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }
    btsSeason1: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-1"] } } } }
          language: { eq: "English" }
          bts: { eq: "YES" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }
    btsSeason2: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-2"] } } } }
          language: { eq: "English" }
          bts: { eq: "YES" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }
    btsSeason3: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["season-3"] } } } }
          language: { eq: "English" }
          bts: { eq: "YES" }
        }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 1
    ) {
      nodes {
        ...WatchEpisodeDataFragment
      }
    }
    season1Read: allPrismicRead(
      filter: { data: { read_categories: { elemMatch: { read_category: { slug: { in: ["season-1"] }, lang: {} } } } } }
      limit: 1
    ) {
      nodes {
        ...ReadDataFragment
      }
    }

    season2Read: allPrismicRead(
      filter: {
        data: {
          read_categories: { elemMatch: { read_category: { slug: { in: ["season-2", "featured"] }, lang: {} } } }
        }
      }
      limit: 1
    ) {
      nodes {
        ...ReadDataFragment
      }
    }

    season3Read: allPrismicRead(
      filter: {
        data: {
          read_categories: { elemMatch: { read_category: { slug: { in: ["season-3", "featured"] }, lang: {} } } }
        }
      }
      limit: 1
    ) {
      nodes {
        ...ReadDataFragment
      }
    }

    latestRead: allPrismicRead(
      limit: 1
      sort: { order: DESC, fields: first_publication_date }
      filter: { lang: {}, data: { read_categories: { elemMatch: { read_category: { slug: { in: "blog" } } } } } }
    ) {
      nodes {
        ...ReadDataFragment
      }
    }
    language: allPrismicCategories(
      filter: { data: { non_english_language: { eq: "Yes" } } }
      sort: { fields: data___priority }
    ) {
      nodes {
        data {
          flagCode: flag_code {
            text
          }
        }
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
    missionStatement: allPrismicMissionStatement {
      edges {
        node {
          data {
            title {
              text
            }
            statement {
              text
              html
            }
            images {
              image {
                url
                alt
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
    allPrismicTeamMembers(
      sort: { fields: data___name___text }
      limit: 3
      filter: { data: { featured: { eq: true } } }
    ) {
      nodes {
        data {
          familyOrigin: family_origin {
            country {
              text
            }
          }
          portraitPhoto: portrait_photo {
            url
          }
          actionPhoto: action_photo {
            url
          }
          name {
            text
          }
          ministryRole: ministry_role {
            text
          }
          birthCountry: birth_country {
            text
          }
          bio {
            text
          }
          facebook {
            text
          }
          instagram {
            text
          }
          twitter {
            text
          }
          email {
            text
          }
          currentCountry: current_country {
            text
          }
        }
        id
      }
    }
    teamSingleMap: file(relativePath: { eq: "team-single-map.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HomePage;
