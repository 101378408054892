import React from 'react';

import { isBrowser } from '../../utils/isBrowser';
import ArrowLeftIcon from '../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../images/icon-arrow-right-short.svg';
import { Link } from 'gatsby';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const ProjectCarousel = ({ projects }) => {
  return (
    <div>
      <div className="projects-lists-sm">
        {projects.map((image, index) => (
          <div className="project-image-container scale-on-hover" key={index}>
            <Link
              to={
                image?.detailsPage?.document?.__typename === 'PrismicLanguageDonationsPage'
                  ? '/language-donations-page'
                  : `/donate/${image.detailsPage.uid}`
              }
            >
              <img
                src={image.thumbnail.url}
                alt={image.title.text}
                style={{
                  objectFit: 'cover',

                  height: '220px',
                  width: '100%',
                }}
              />
              <div>
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: 500,
                    bottom: 0,
                  }}
                >
                  {image.title.text}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="project-carousel">
        <OwlCarousel
          className="carousel-stage owl-carousel la-rounded-nav"
          responsiveClass
          navigation="true"
          items={3}
          center
          loop
          margin={80}
          navText={[
            `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
            `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
          ]}
          responsive={{
            0: {
              items: 2.2,
              nav: true,
              dots: false,
            },
            768: {
              items: 3,
              nav: true,
              dots: true,
            },

            1200: {
              items: 3,
              nav: true,
              dots: true,
            },
          }}
        >
          {projects.map((image, index) => (
            <Link
              to={
                image?.detailsPage?.document?.__typename === 'PrismicLanguageDonationsPage'
                  ? '/language-donations-page'
                  : `/donate/${image.detailsPage.uid}`
              }
            >
              <div className="image-container scale-on-hover" key={index}>
                <img
                  src={image.thumbnail.url}
                  alt={image.title.text}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                />
                <p
                  style={{
                    position: 'absolute',
                    paddingLeft: '34px',
                    color: 'white',
                    fontSize: '23px',
                    fontWeight: 700,
                    bottom: 0,
                    paddingBottom: '34px',
                    lineHeight: '31px',
                  }}
                >
                  {image.title.text}
                </p>
              </div>
            </Link>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default ProjectCarousel;
